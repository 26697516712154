.skill-main {
  display: flex;
  justify-content: center;
  padding: 0.7rem 0 0 0;
  text-align: center;
  overflow: hidden;
}
.skill-content {
  height: 100%;
  width: 100%;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skill-start {
  position: relative;
  color: gray;
  font-size: 1.1rem;
  letter-spacing: 4px;
}
.skill-start::before {
  content: "";
  display: none;
  position: absolute;
  top: 0.5rem;
  left: 31rem;
  background: rgb(206, 203, 203);
  width: 1.5rem;
  height: 0.3rem;
}
.skill-end {
  font-size: 1.5rem;
}
.skill-card {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.skill-card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: black;
  padding: 0 2rem 0 2rem;
  border-radius: 1rem;
  margin: 2rem 3rem;
  border: 2px solid #f3f2f2;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 9%);
  height: 14rem;
  overflow: hidden;
  position: relative;
  width: 14rem;
}

.image-wrapper {
  background-color: rgb(233, 246, 250);
  height: 3rem;
  width: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.image-wrapper svg {
  font-size: 2rem;
}
.skill-description {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1rem;
}

@media screen and (max-width: 1165px) {
  .skill-card-section {
    margin: 2rem 2rem;
  }
}
@media screen and (max-width: 1069px) {
  .skill-card-section {
    margin: 2rem 1rem;
  }
}
@media screen and (max-width: 972px) {
  .skill-card-section {
    width: 13rem;
  }
}
@media screen and (max-width: 926px) {
  .skill-card-section {
    margin: 2rem 0.5rem;
  }
}

@media screen and (max-width: 700px) {
  .skill-description {
    display: none;
  }

  .skill_title {
    font-size: 1.5rem;
  }
  .image-wrapper {
    height: 10rem;
    width: 10rem;
  }
  .image-wrapper svg {
    font-size: 5rem;
  }
}
@media screen and (max-width: 435px) {
  .skill-card-section {
    margin: 2rem 0.5rem;
    width: 10rem;
  }
}
@media screen and (max-width: 360px) {
  .skill-card-section {
    width: 7rem;
  }
}
