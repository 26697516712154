.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content {
  height: 100vh;
  width: 100vw;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.writer-load {
  margin-top: 1rem;
}
.spine {
  height: 9rem;
  width: 9rem;
  position: absolute;
  border-bottom: 0.1rem solid black;
  border-radius: 50%;
  animation: rot 2s infinite linear;
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 700px) {
  .content {
    font-size: 1.5rem;
  }
  .spine {
    height: 12rem;
    width: 12rem;
  }
}
