.project-main {
  display: flex;
  justify-content: center;
  padding: 3.5rem 0 2rem 0;
}
.project-start {
  position: relative;
  padding-left: 2rem;
  color: gray;
  letter-spacing: 4px;
}
.project-start::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.5rem;
  left: 0rem;
  background: rgb(206, 203, 203);
  width: 1.5rem;
  height: 0.3rem;
}
.project-end {
  font-size: 1.5rem;
}
.project-card {
  display: grid;
  gap: 1rem 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  margin-top: 1rem;
  width: 75vw;
  transition: all 0.2 linear;
}
.span-4 {
  grid-column-start: 2;
  grid-column-end: 3;
}
.project-card-section {
  height: 19rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: rgb(233, 246, 250);
  color: black;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 9%);
  flex: 1 1 200px;
  margin-bottom: 2rem;
}

.project-image-wrapper {
  background-color: rgb(233, 246, 250);
  height: 70%;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.project-image-wrapper svg {
  font-size: 2rem;
}
.project_title {
  margin: 0 0 0 1rem;
  font-size: 1.1rem;
  text-align: left;
}

.project-info {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%;
  width: 100%;
  overflow: hidden;
}
.project-info::after {
  content: "";
  position: absolute;
  opacity: 0;
  transition: all 0.4s ease;
  transform: translateY(150px);
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
}
.project-info:hover::after {
  opacity: 1;
  transform: translateY(0);
}
.project-info .p-title {
  z-index: 1;
  transform: translateY(150px);
  transition: all 0.4s ease;
  opacity: 0;
  color: white;
  font-size: 1rem;
  font-weight: 500;
}
.project-info:hover .p-title {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.input-value {
  margin: 0 0 0 1rem;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 0.5rem;
  color: gray;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  margin-right: 1rem;
}
.project-link {
  display: flex;
  align-items: center;
}
.project-link a svg {
  font-size: 1.7rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 1055px) {
  .span-4 {
    grid-column-start: auto;
    grid-column-end: auto;
  }
}
