@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 700px) {
  .App {
    width: 100%;
    height: 100vh;
  }
  html {
    font-size: 74%;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 60px;
}
::-webkit-scrollbar-thumb {
  background-color: #0087ca;
}
