nav {
  width: 100vw;
  height: 5rem;
  font-family: "Poppins", sans-serif;
  position: fixed;
  z-index: 10;
}

.nav-img {
  width: 70px;
  height: 80px;
}

.toggle {
  background-color: #0d6895;
  transition: all 0.5s ease-in-out;
}
.navbar-ul.toggle {
  background-color: transparent;
}

.nav-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15%;
  padding-left: 14%;
  justify-content: space-between;
}

.nav-items::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.nav-items:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
ul {
  display: flex;
  flex-flow: row nowrap;
  padding: 0px 0px 0px 10rem;
  background-color: transparent;
}
.link {
  padding: 0 3rem 0 0;
}

.nav-items {
  list-style: none;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn1 {
  margin-right: 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
}
.btn1 svg {
  font-size: 1.5rem;
}
.hamburger {
  display: none;
}

.btn2 {
  border: 2px solid #0087ca;
  background-color: transparent;
  cursor: pointer;
  border-radius: 3px;
  padding: 0.5rem 2rem 0.5rem 2rem;
}

.toggle #navbar-btn {
  border: 2px solid #fff;
}

/* media query start  */

@media screen and (max-width: 1100px) {
  .nav-content {
    padding-left: 7%;
    padding-right: 7%;
  }
  .link {
    padding: 0 2rem 0 0;
  }
}
@media screen and (max-width: 887px) {
  ul {
    padding: 0px 0px 0px 6rem;
  }
  .link {
    padding: 0 1rem 0 0;
  }
}
@media screen and (max-width: 785px) {
  ul {
    padding: 0px 0px 0px 4rem;
  }
}

@media screen and (max-width: 700px) {
  nav {
    width: 100%;
    height: 6.1rem;
    transition: 0.5s;
  }
  .nav-img {
    width: 83px;
    height: 74px;
  }
  .nav-main {
    padding: 1rem 0rem 0rem 3rem;
  }
  .nav-content {
    padding-right: 0;
    padding-left: 0;
    justify-content: space-between;
  }
  .light-mode-ul {
    background-color: #fff;
  }
  .dark-mode-ul {
    background-color: #2e2e2e;
  }
  .navbar-ul.toggle {
    background-color: #0d6895;
  }
  #navbar-btn {
    display: none;
  }
  .link {
    padding: 0.2rem 4rem;
  }
  ul {
    flex-flow: column nowrap;
    position: absolute;
    top: 5.1rem;
    right: 0;
    height: 93vh;
    width: 100%;
    transition: 0.5s;
    animation: slide 0.3s;
    visibility: visible;
  }

  @keyframes slide {
    0% {
      transform: translateX(100%);
      opacity: 0;
      display: none;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
      display: block;
    }
  }
  @keyframes slide-none {
    0% {
      transform: translateX(0%);
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
      display: none;
    }
  }

  .none {
    animation: slide-none 0.3s;
    animation-fill-mode: forwards;
  }

  .nav-items {
    margin: 4rem;
    font-size: 2rem;
  }

  .btn1 svg {
    font-size: 2.5rem;
  }
  .hamburger {
    display: block;
    margin-right: 0.5rem;
    background-color: transparent;
    border: none;
    border-radius: 1.5rem;
    cursor: pointer;
    transition: all 0.5s;
  }
  .hamburger svg {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 500px) {
  .nav-img {
    width: 66px;
    height: 58px;
  }
  .btn1 svg {
    font-size: 2.2rem;
  }
  .hamburger svg {
    font-size: 3rem;
  }
}
@media screen and (max-width: 350px) {
  .nav-img {
    width: 57px;
  }
  .btn1 svg {
    font-size: 2rem;
  }
  .hamburger svg {
    font-size: 2.8rem;
  }
}
