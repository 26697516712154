.main-contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 7rem 0 5rem 0rem;
  height: 65vh;
  width: 100%;
}
.contact-start {
  position: relative;
  padding-left: 2rem;
  color: gray;
  letter-spacing: 4px;
}
.contact-start::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.5rem;
  left: 0rem;
  background: rgb(206, 203, 203);
  width: 1.5rem;
  height: 0.3rem;
}
.contact-end {
  font-size: 1.5rem;
}
.contact-contain {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.contact-first {
  width: 39rem;
  display: flex;
  border-right: 2px solid black;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 14%;
}
.contact-form {
  height: 2rem;
  width: 80%;
  margin: 0 0 2rem 0;
  border-radius: 0.2rem;
  border: none;
  padding: 0 0 0 1rem;
  outline: none;
  font-size: 1rem;
}
textarea {
  height: 8rem;
  width: 80%;
  resize: none;
  border-radius: 0.2rem;
  border: none;
  padding: 0 0 0 1rem;
  margin: 0 0 2rem 0;
  outline: none;
  font-size: 1rem;
}
.contact-btn {
  height: 2rem;
  width: 80%;
  background-color: #0087ca;
  color: white;
  border: none;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.contact-second {
  justify-content: center;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 12%;
  flex-direction: column;
}
.contact-contain,
.contact-second {
  flex: 1;
}
.contact-second-all {
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  height: 5rem;
  width: 90%;
  align-items: center;
  padding-right: 1rem;
  margin: 0 0 1.5rem 0;
}
.contact-icon {
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(233, 246, 250);
  margin: 0 2rem 0 2rem;
  border-radius: 0.5rem;
}
.contact-icon svg {
  font-size: 2rem;
}
.contact-details {
  color: gray;
  font-size: 0.9rem;
}
.contact-name {
  color: black;
}

@media screen and (max-width: 1100px) {
  .contact-first {
    padding-left: 8%;
  }
  .contact-second {
    padding-right: 6%;
  }
}
@media screen and (max-width: 890px) {
  .main-contact {
    height: auto;
    padding: 3rem 0 5rem 0rem;
    overflow: hidden;
  }
  .contact-contain {
    flex-direction: column;
  }
  .contact-first {
    padding-left: 0;
    align-items: center;
    border-right: none;
    border-bottom: 2px solid black;
    padding: 2rem 0rem;
  }
  .contact-second {
    padding: 2rem 0 0 0;
    flex-direction: row;
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .contact-second-all {
    margin: 0;
    width: auto;
    scale: 0.8;
  }
  .contact-icon {
    width: auto;
  }
  .contact-form {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .contact-form {
    height: 4rem;
  }
  .contact-btn {
    height: 4rem;
    font-size: 1.5rem;
  }
  .contact-second-all {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .contact-details {
    font-size: 1.1rem;
  }
  .contact-name {
    font-size: 1.5rem;
  }
  .contact-form {
    border-radius: 1.5rem;
  }
  textarea {
    border-radius: 1.5rem;
    padding-top: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .contact-first {
    width: 80%;
  }
  .contact-form {
    width: 90%;
  }
  textarea {
    width: 90%;
  }
  .contact-second-all {
    margin: 0;
  }
}
@media screen and (max-width: 400px) {
  .contact-second {
    width: 150%;
    scale: 0.8;
  }
}
