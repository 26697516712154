.home-main {
  height: 90vh;
  padding: 5rem 0 0 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-content {
  display: flex;
  width: 70%;
  align-items: center;
}
.home-first {
  width: 50%;
}
.name-start {
  position: relative;
  padding-left: 2rem;
  color: gray;
}

.name-start::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  left: 0rem;
  background: rgb(206, 203, 203);
  width: 1.5rem;
  height: 0.3rem;
}
.name-end {
  font-size: 4rem;
  line-height: 4rem;
}
span {
  color: #0087ca;
}
.writer {
  display: flex;
  margin-top: 1rem;
}
.writer span {
  margin-left: 0.2rem;
}

.home-icons svg {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 2rem 1rem 0;
  cursor: pointer;
  color: white;
}
.home a {
  text-decoration: none;
}

#home-btn {
  display: none;
}
.home-second {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.image-div {
  height: 20rem;
  border-radius: 2rem;
  width: 17rem;
  position: relative;
  background-image: url(../../assets/imgaes/anish.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 0;
}
.image-div::before {
  transform: rotate(-10deg);
  background-color: #0087ca;
  content: "";
  border-radius: 2rem;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
}
.image-div:hover {
  box-shadow: gray 0px 5px 15px;
}
.image-div:hover::before {
  transition: all 1s ease-in-out;
  transform: rotate(0deg);
}

/* media query start  */

@media screen and (max-width: 1100px) {
  .home-content {
    width: 86%;
  }
}
@media screen and (max-width: 700px) {
  .home-main {
    display: flex;
    padding: 0rem 0 0 0;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .home-content {
    height: 100%;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
  }
  .home-first {
    width: 100%;
    text-align: center;
    padding: 2rem 0 2rem 0;
  }
  .name-start {
    font-size: 2.5rem;
    padding: 0;
  }
  .name-start::before {
    top: 1.5rem;
    display: none;
    left: 11rem;
  }
  .writer {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .writer,
  .home-description {
    font-size: 1.7rem;
  }
  .writer {
    margin-top: 0;
  }
  .home-description {
    padding: 0;
  }
  .home-icons svg {
    height: 2.5rem;
    width: 3.5rem;
  }
  .image-div {
    width: 22rem;
  }

  .home-second {
    width: 50%;
    margin-top: 5rem;
    display: block;
  }
  .home-resume {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #home-btn {
    display: block;
    padding: 0.5rem 5rem 0.5rem 5rem;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 460px) {
  .image-div {
    height: 18rem;
    width: 18rem;
  }
  .home-second {
    margin-right: 3rem;
  }
  .home-first {
    padding: 0rem 0rem 2rem 0rem;
  }
  .name-start {
    font-size: 2rem;
  }
  .name-end {
    font-size: 3.2rem;
  }
  .writer,
  .home-description {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 390px) {
  .home-second {
    margin: 5rem 4rem 0 0;
  }
  .image-div {
    height: 16rem;

    width: 16rem;
  }
}
@media screen and (max-width: 314px) {
  .name-end {
    font-size: 3rem;
  }
}
@media screen and (max-width: 300px) {
  .home-second {
    margin: 5rem 6rem 0 0;
  }
}
